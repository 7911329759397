'use client'

import React, { ReactNode } from 'react'
import { State, WagmiProvider } from 'wagmi'
import { createAppKit } from '@reown/appkit/react'
import { reconnect } from '@wagmi/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { getFromLocalStorage, isInLocalStorage } from 'lib/storage'

import { chains, config, projectId, wagmiAdapter, metadata, networks } from './config'

// Setup queryClient
const queryClient = new QueryClient()

if (!projectId) throw new Error('Project ID is not defined')

const theme = getFromLocalStorage("color-theme") || (!isInLocalStorage("color-theme") ? "light" : "dark") || "light";

reconnect(config);

const featuredWalletIds = [
  // https://walletconnect.com/explorer/metamask
  "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662", // bitget wallet
  "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // metamask
  "18388be9ac2d02726dbac9777c96efaac06d744b2f6d580fccdd4127a6d01fd1", // Rabby
  "e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b", // wallectconnect
  "c7708575a2c3c9e6a8ab493d56cdcc56748f03956051d021b8cd8d697d9a3fd2", // fox wallet
  // "ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef", // imtoken
]

if (typeof window !== 'undefined') {
  if ((new URL(location.href)?.search?.includes('_is_telegram'))) {
    featuredWalletIds.push(
      "21c3a371f72f0057186082edb2ddd43566f7e908508ac3e85373c6d1966ed614", // Bitget Wallet Lite
    )
  }
}

const includeWalletIds = process.env.NEXT_PUBLIC_MORPH_WALLETCONNECT_includeWalletIds ? [
  "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // 2、 MetaMask 

  "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0", // 5、 Trust Wallet
  "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709", // 6、 OKX Wallet

  "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662", // 1、 Bitget Wallet

  "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4", // 7、 Binance web3 wallet
  "c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a", // 8、 Uniswap Wallet
  "ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef", // 11、 imToken
  "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa", // 10、 Coinbase Wallet
  "18388be9ac2d02726dbac9777c96efaac06d744b2f6d580fccdd4127a6d01fd1", // 3、 Rabby Wallet
  "c7708575a2c3c9e6a8ab493d56cdcc56748f03956051d021b8cd8d697d9a3fd2", // 4、 Fox Wallet

  "21c3a371f72f0057186082edb2ddd43566f7e908508ac3e85373c6d1966ed614", // Bitget Wallet Lite

  // "a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393", // 9、 Phantom // Solana
  // "c286eebc742a537cd1d6818363e9dc53b21759a1e8e5d9b263d0c03ec7703576", // 12、 1inch Wallet
  // "19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927", // 13、 Ledger Live、 
  // "2bd8c14e035c2d48f184aaa168559e86b0e3433228d3c4075900a221785019b0", // 14、 BackPack Wallet、 
  // "225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f", // 15、 Safe Wallet、 
  // "20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66", // 16、 TokenPocket
  // "8837dd9413b1d9b585ee937d27a816590248386d9dbf59f5cd3422dbbb65683e", // 17、 Robinhood Wallet
  // "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369", // 18、 Rainbow Wallet
  // "aba1f652e61fd536e8a7a5cd5e0319c9047c435ef8f7e907717361ff33bb3588", // 19、 Gate Wallet
  // "15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f", // 20、 Bybit Wallet;

  // "bc949c5d968ae81310268bf9193f9c9fb7bb4e1283e1284af8f2bd4992535fd6", // 21、 Argent
  // "e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4", // 22、 Exodus
  // "84b43e8ddfcd18e5fcb5d21e7277733f9cccef76f7d92c836d0e481db0c70c04", // 23、 Blockchain.com
  // "8b830a2b724a9c3fbab63af6f55ed29c9dfa8a55e732dc88c80a196a2ba136c6", // 24、 Magic Eden
  // "18450873727504ae9315a084fa7624b5297d2fe5880f0982979c17345a138277", // 25、 Kraken Wallet
  // "344d0e58b139eb1b6da0c29ea71d52a8eace8b57897c6098cb9b46012665c193", // 26、 Timeless X
  // "9414d5a85c8f4eabc1b5b15ebe0cd399e1a2a9d35643ab0ad22a6e4a32f596f0", // 27、 Zengo Wallet
  // "f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d", // 28、 Crypto.com | DeFi Wallet
  // "ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18", // 29、 Zerion Wallet
  // "2a3c89040ac3b723a1972a33a125b1db11e258a6975d3a61252cd64e6ea5ea01", // 30、 Coin98 Super Wallet
  // "0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150", // 31、 Safepal
  // "f896cbca30cd6dc414712d3d6fcc2f8f7d35d5bd30e3b1fc5d60cf6c8926f98f", // 32、 XDEFI Wallet
  // "1aedbcfc1f31aade56ca34c38b0a1607b41cccfa3de93c946ef3b4ba2dfab11c", // 33、 OneKey
  // "1aa28414c95f5024133faf5766d376bb9c853c280d158cd3e22dc2b7b0a95a2d", // 34、 Block Wallet
  // "a9751f17a3292f2d1493927f0555603d69e9a3fcbcdf5626f01b49afa21ced91", // 35、 Frame Wallet

  // "5864e2ced7c293ed18ac35e0db085c09ed567d67346ccb6f58a0327a75137489", // 36、 Fireblocks
  // "1ca0bdd4747578705b1939af023d120677c64fe6ca76add81fda36e350605e79", // 37、 Solflare // solana
  // "7674bb4e353bf52886768a3ddc2a4562ce2f4191c80831291218ebd90f5f5e26", // 38、 Math Wallet
  // "138f51c8d00ac7b9ac9d8dc75344d096a7dfe370a568aa167eabc0a21830ed98", // 39、 Alpha Wallet
  // "8308656f4548bb81b3508afe355cfbb7f0cb6253d1cc7f998080601f838ecee3", // 40、 Unstoppable Domains
  // "47bb07617af518642f3413a201ec5859faa63acb1dd175ca95085d35d38afb83", // 41、 KEYRING PRO
  // "85db431492aa2e8672e93f4ea7acf10c88b97b867b0d373107af63dc4880f041", // 42、 Frontier
  // "afbd95522f4041c71dd4f1a065f971fd32372865b416f95a0b1db759ae33f2a7", // 43、 Omni
  // "76a3d548a08cf402f5c7d021f24fd2881d767084b387a5325df88bc3d4b6f21b", // 44、 LOBSTR Wallet // Stellar PubNet
  // // "", // 45、 Obivous
  // "2c81da3add65899baeac53758a07e652eea46dbb5195b8074772c62a77bbf568", // 46、 Ambire Wallet
  // "802a2041afdaf4c7e41a2903e98df333c8835897532699ad370f829390c6900f", // 47、 Infinity Wallet
  // "7424d97904535b14fe34f09f63d8ca66935546f798758dabd5b26c2309f2b1f9", // 48、 Bridge Wallet
  // "c482dfe368d4f004479977fd88e80dc9e81107f3245d706811581a6dfe69c534", // 49、 NOW Wallet
  // "107bb20463699c4e614d3a2fb7b961e66f48774cb8f6d6c1aee789853280972c", // 50、 Bitcoin.com Wallet
] : []

// Create modal
createAppKit({
  adapters: [wagmiAdapter],
  networks,
  // defaultNetwork: chains?.[0],
  projectId,
  metadata,
  features: {
    analytics: false,
    swaps: false,
    onramp: false,
    ...(process.env.NEXT_PUBLIC_MORPH_WALLETCONNECT_SOCIAL_LOGIN ? {
      email: true, // default to true
      socials: ['x', 'discord', 'github', 'facebook', 'google', 'apple'],
      emailShowWallets: true, // default to true
      connectMethodsOrder: ['wallet', 'email', 'social'],
    } : {
      email: false,
      socials: [],
      emailShowWallets: false,
    }),
  },
  excludeWalletIds: [
    'walletConnect'
  ],
  enableWalletConnect: true,
  // enableEIP6963: true,
  // enableCoinbase: true,
  themeVariables: {
    "--w3m-accent": "#14A800",
    "--w3m-color-mix": "#fff",
    "--w3m-color-mix-strength": 40,
    "--w3m-border-radius-master": "2px",
    "--w3m-z-index": 100001,
  },
  featuredWalletIds,
  includeWalletIds,
  allWallets: process.env.NEXT_PUBLIC_MORPH_WALLETCONNECT_includeWalletIds ? 'SHOW' : 'HIDE',
  allowUnsupportedChain: true,
  chainImages: {
    // qanet
    53077: 'https://bridge-holesky.morphl2.io/v4/Logo/Morphi-L2-white.svg',
    900: 'https://bridge-holesky.morphl2.io/images/logo/holesky.svg',
    // holesky
    2810: 'https://bridge-holesky.morphl2.io/v4/Logo/Morphi-L2-white.svg',
    17000: 'https://bridge-holesky.morphl2.io/images/logo/holesky.svg',
    // sepolia
    2710: 'https://bridge-holesky.morphl2.io/logo/svg/logo-l2.svg',
    11155111: 'https://bridge-holesky.morphl2.io/images/logo/sepolia.svg',
    // mainnet
    1: 'https://bridge-holesky.morphl2.io/images/logo/ethereum2.svg',
    2818: 'https://bridge-holesky.morphl2.io/images/logo/morph2.svg',
  },
  themeMode: theme as any,
  termsConditionsUrl: 'https://www.morphl2.io/terms',
  privacyPolicyUrl: 'https://www.morphl2.io/privacy'
})

export default function Web3ModalProvider({
  children,
  initialState
}: {
  children?: ReactNode
  initialState?: State
}) {
  return (
    <WagmiProvider config={config} initialState={initialState} reconnectOnMount={true}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
