import dayjs from 'lib/date/dayjs';
import { getFromLocalStorage } from './storage';

export const env = (typeof window !== "undefined") && (
  ["Devnet", "Qanet", undefined].includes(
    process.env.NEXT_PUBLIC_NETWORK_TYPE
  ) ||
  getFromLocalStorage('morph.Console') ||
  window?.location.hostname === "localhost" ||
  (new URL(window.location.href)?.search?.includes('_is_test'))
);

export const Console = (function() {
  if (typeof window === 'undefined') return console;

  return {
    log: function(...text: any) {
      if (!env) {
        return;
      }
      // eslint-disable-next-line no-console
      console.log(dayjs(new Date()).format('YYYY-M-D h:m:s'), ...text);
    },
    info: function(...text: any) {
      if (!env) {
        return;
      }
      // eslint-disable-next-line no-console
      console.info(dayjs(new Date()).format('YYYY-M-D h:m:s'), ...text);
    },
    warn: function(...text: any) {
      if (!env) {
        return;
      }
      // eslint-disable-next-line no-console
      console.warn(dayjs(new Date()).format('YYYY-M-D h:m:s'), ...text);
    },
    error: function(...text: any) {
      // eslint-disable-next-line no-console
      console.error(dayjs(new Date()).format('YYYY-M-D H:mm:ss'), ...text);
    },
  };
})();
