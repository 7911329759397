'use client';

import type { ChakraProps } from '@chakra-ui/react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { isTelegramEnvironment, overrideWindowOpen } from "@bitget-wallet/omni-connect";

import * as Sentry from '@sentry/react';

import React, { useEffect } from 'react';

import { AppContextProvider } from 'lib/contexts/app';
import { ChakraProvider } from 'lib/contexts/chakra';
import { MarketplaceContextProvider } from 'lib/contexts/marketplace';
import { ScrollDirectionProvider } from 'lib/contexts/scrollDirection';
import { growthBook } from 'lib/growthbook/init';
import useLoadFeatures from 'lib/growthbook/useLoadFeatures';
import useNotifyOnNavigation from 'lib/hooks/useNotifyOnNavigation';
import { SocketProvider } from 'lib/socket/context';
import AppErrorBoundary from 'ui/shared/AppError/AppErrorBoundary';
import type { AppProps } from 'next/app';
import GoogleAnalytics from 'ui/shared/GoogleAnalytics';
import Layout from 'ui/shared/layout/Layout';
import type { NextPageWithLayout } from 'nextjs/types';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';


import 'lib/setLocale';
import './fonts.scss';
import './globals.scss';

// import 'focus-visible/dist/focus-visible';
import config from 'configs/app';
import pak from '../package.json';
import useQueryClientConfig from 'lib/api/useQueryClientConfig';
import { log } from 'lib/log';
import Web3ModalProvider from 'lib/walletconnect/provider';
import { StayTuned } from 'ui/stay_tuned';
import { Console, env } from 'lib/console';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
}

const ERROR_SCREEN_STYLES: ChakraProps = {
  h: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: 'fit-content',
  maxW: '800px',
  margin: '0 auto',
  p: { base: 4, lg: 0 },
};

// @ts-ignore
console.log(pak.version);

function MyApp({ Component, pageProps }: AppPropsWithLayout) {

  useLoadFeatures();
  useNotifyOnNavigation();

  useEffect(() => {
    log({
      page: 'testnet_explorer_page',
    });
  });

  useEffect(() => {
    try {
      const initOverrideWindowOpen = async () => {
        // Check if it's a Telegram Mini App environment
        const isTMA = await isTelegramEnvironment();
        if (!isTMA) {
          return;
        }
        // Execute @bitget-wallet/omni-connect initialization
        overrideWindowOpen();
      };

      if ((typeof window !== 'undefined') && (new URL(window.location.href)?.search?.includes('_is_telegram'))) {
        initOverrideWindowOpen();
      }
    } catch(err) {
      Console.log('initOverrideWindowOpen err', err);
    }
  }, []);


  useEffect(() => {
    let vConsole: any;

    const setupVConsole = async () => {
      const VConsole = (await import('vconsole')).default
      vConsole = new VConsole()
    }
    
    if (env) {
      setupVConsole();
    }

    () => {
      vConsole && vConsole.destroy()
    }
  }, []);
  
  const queryClient = useQueryClientConfig();

  const handleError = React.useCallback((error: Error) => {
    Sentry.captureException(error);
  }, []);

  const getLayout = Component.getLayout ?? ((page) => <Layout>{ page }</Layout>);

  return (
    <>{
      process.env.NEXT_PUBLIC_MORPH_STAY_TUNED ? <StayTuned /> :
      <ChakraProvider cookies={ pageProps.cookies }>
        <AppErrorBoundary
          { ...ERROR_SCREEN_STYLES }
          onError={ handleError }
        >
          <Web3ModalProvider>
            <AppContextProvider pageProps={ pageProps }>
              <QueryClientProvider client={ queryClient }>
                <GrowthBookProvider growthbook={ growthBook }>
                  <ScrollDirectionProvider>
                    <SocketProvider url={ `${ config.api.socket }${ config.api.basePath }/socket/v2` }>
                      <MarketplaceContextProvider>
                        { getLayout(<Component { ...pageProps }/>) }
                      </MarketplaceContextProvider>
                    </SocketProvider>
                  </ScrollDirectionProvider>
                </GrowthBookProvider>
                <ReactQueryDevtools buttonPosition="bottom-left" position="left"/>
                <GoogleAnalytics/>
              </QueryClientProvider>
            </AppContextProvider>
          </Web3ModalProvider>
        </AppErrorBoundary>
      </ChakraProvider>
    }</>
  );
}

export default MyApp;
