'use client'

import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import { CreateConnectorFn } from 'wagmi';
import currentChain, { currentL1Chain } from 'lib/web3/currentChain';
import { holesky, mainnet } from 'viem/chains';
import configs from 'configs/app';

import { safe } from 'wagmi/connectors';
import { type AppKitNetwork } from '@reown/appkit/networks';

// Get projectId from https://cloud.walletconnect.com
const feature = configs.features.blockchainInteraction;
export const projectId = feature.walletConnect.projectId

if (!projectId) throw new Error('Project ID is not defined')

export const metadata = {
  name: process.env.MORPH_WEBSITE_NAME || "Morph Explorer",
  description:
    process.env.MORPH_WEBSITE_DESCRIPTION ||
    "Upgrade your blockchain experience with Morph - the secure, decentralized, cost-efficient, and high-performing EVM-equivalent rollup. Try it now!",
  url: process.env.MORPH_WEBSITE_URL || "https://explorer.morphl2.io",
  icons: [
    process.env.MORPH_WEBSITE_ICON || 'https://www.morphl2.io/logo/morph-256*256.png',
  ],
};

// Create wagmiConfig [1].includes(Number(val.id)) || 
export const chains = [currentChain];

if (currentL1Chain.name) {
  chains.push(currentL1Chain)
}

let connectors: CreateConnectorFn[] = [
  safe({
    allowedDomains: [/^app\.safe\.global$/, /.*\.morphl2\.io$/, /localhost:3003/],
    debug: false,
    // shimDisconnect: false,
  }),
];

if (projectId) {
  // A WalletConnect ID is provided so we add the Connector for testing purposes
  connectors = [...connectors];
}

export const networks = [chains[0], ...chains.slice(1)] as [AppKitNetwork, ...AppKitNetwork[]];

export const wagmiAdapter = new WagmiAdapter({
  // chains: [chains[0], ...chains.slice(1)],
  // syncConnectedChain: false,
  // transports: [mainnet, ...chains].reduce((previousValue, currentValue, currentIndex, arr) => {
  //   previousValue[Number(currentValue.id)] = http(currentValue.rpcUrls.default.http[0]);
  //   console.log('previousValue', previousValue);
  //   return previousValue;
  // }, {}),
  // client({ chain }) { 
  //   return createClient({ chain, transport: http(chain.rpcUrls.default.http[0]) }) 
  // }, 
  projectId,
  ssr: true,
  connectors,
  networks: networks as any,
})

export const config = wagmiAdapter.wagmiConfig
